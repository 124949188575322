<template>
  <!-- asignar nuevo apoderado -->
  <div v-if="checkPermission(['CREATE_ASSIGN_REPRESENTATIVES', 'ALLOW_ALL']) && (actions.name == 'create' || actions.name == 'edit')">
    <create-component
      :actions="actions"
      @change-type-view="assignView('list')"
    />
  </div>
  <!--/ asignar nuevo apoderado -->

  <!-- información de apoderados -->
  <b-row v-else>
    <template v-if="currentRepresentatives">
      <!-- detalle de apoderado -->
      <b-col cols="12">
        <b-overlay
          :show="applyOverlayRep"
          :class="applyOverlayRep ? 'p-2 mb-1' : 'mb-1'"
        >
          <template #overlay>
            <div class="d-flex align-items-center justify-content-center mt-1">
              <b-spinner
                type="border"
                variant="primary"
              />
            </div>
            <div class="d-flex justify-content-center">
              <p class="pt-1">
                {{ $t('loading') }}
              </p>
            </div>
          </template>
          <detail-component
            :apply-overlay="applyOverlayRep"
            :actions="actions"
            :n-representatives="paginate.total"
            :check-permission="checkPermission"
            @stop-overlay="applyOverlayRep = false"
            @edit-representative="editAssignRepresentatives"
            @delete-representative="deleteAssignRepresentatives"
          />
        </b-overlay>
      </b-col>
      <!--/ detalle de apoderado -->

      <!-- historico de apoderados -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.4)'"
          v-b-toggle.collapse-1
          pill
          block
          variant="outline-primary"
        >
          {{ $t('generic.historicalData') }}
        </b-button>
        <b-collapse
          id="collapse-1"
          class="mt-2"
        >
          <b-row v-if="checkPermission(['CREATE_ASSIGN_REPRESENTATIVES', 'ALLOW_ALL'])">
            <b-col cols="12 mb-1">
              <span class="float-right">
                {{ $t( 'clubs.representatives.addNewRepresentative') }}
                <b-button
                  class="ml-1 btn-icon rounded-circle"
                  variant="primary"
                  :style="colorPrimaryBtn"
                  @click="assignView('create')"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table
                ref="historyRefPOA"
                hover
                class="position-relative i-min-height-table"
                :items="representatives"
                :fields="assignRepresentativesFields"
                :no-border-collapse="true"
                responsive
                primary-key="id"
                show-empty
                :empty-text="$t('generic.noRecordsFound')"
              >
                <template #cell(instrument_numbers)="row">
                  <span
                    v-for="(instrument_x, index) in row.item.instrument_numbers"
                    :key="instrument_x.hash"
                  >
                    <span v-if="index != 0">,</span>
                    {{ instrument_x.instrument_number }}
                  </span>
                </template>
                <template #cell(partners)="row">
                  <span
                    v-for="(partner_x, index) in row.item.partners"
                    :key="partner_x.hash"
                  >
                    <span v-if="index != 0">,</span>
                    {{ partner_x.name }}
                  </span>
                </template>
                <template #cell(representatives)="row">
                  <span
                    v-for="(representative_x, index) in row.item.representatives"
                    :key="representative_x.hash"
                  >
                    <span v-if="index != 0">,</span>
                    {{ representative_x.name }}
                  </span>
                </template>
                <template #cell(date_init)="row">
                  <span v-if="row.item.undefined_date">{{ $t('generic.undefinedDate') }}</span>
                  <span v-else> {{ row.item.date_init | formatDate() }} {{ $t('clubs.representatives.to') }} {{ row.item.date_end | formatDate() }}</span>
                </template>
                <template #cell(show_details)="row">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    class="i-check-hide-co"
                    @change="row.toggleDetails"
                    @input="assignDetailRep(row.item.hash, row.detailsShowing, row.index)"
                  >
                    <span
                      class="i-text-details-so"
                      :class="{'text-primary' : row.detailsShowing }"
                    >
                      {{ $t('buttons.details') }}
                      <feather-icon
                        icon="ArrowDownIcon"
                        :class="row.detailsShowing ? 'i-icon-arrow-down' : 'i-icon-arrow-up'"
                      />
                    </span>
                  </b-form-checkbox>
                </template>
                <template #row-details="row">
                  <div v-if="actionsTable.showDetail">
                    <detail-in-table
                      :actions="actionsTable"
                      :n-representatives="paginate.total"
                      :check-permission="checkPermission"
                      @edit-representative="editAssignRepresentatives"
                      @delete-representative="deleteAssignRepresentatives"
                    />
                  </div>
                </template>
              </b-table>
              <app-paginator
                :data-list="paginate"
                @pagination-data="changePaginate"
              />
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
      <!--/ historico de apoderados -->
    </template>

    <!-- Vista principal registro -->
    <b-col
      v-else
      cols="12"
      class="i-min-height-register-so"
    >
      <first-view
        :text-one="$t('clubs.representatives.registerRepresentative')"
        :text-two="$t('clubs.representatives.continueRepresentative')"
        :text-in-button="$t('clubs.representatives.newRepresentative')"
        :color-primary-btn="colorPrimaryBtn"
        @show-register-component="assignView"
      />
    </b-col>
    <!--/ Vista principal registro -->

    <b-col md="12 mt-1">
      <hr>
      <b-button
        class="float-right ml-1"
        variant="secondary"
        pill
        @click="$router.push({ name: 'clubs' })"
      >
        {{ $t('buttons.back') }}
      </b-button>
    </b-col>
  </b-row>
  <!--/ información de apoderados -->
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters, mapActions } from 'vuex'
import { BTable, BButton, BCollapse, VBToggle, BOverlay, BFormCheckbox } from 'bootstrap-vue'
import checkPermission from '@/auth/permissions'
import representativeService from '@/services/representativeService'
import Ripple from 'vue-ripple-directive'
import AppPaginator from '@/views/components/app-paginator/AppPaginator.vue'
import CreateComponent from './Create.vue'
import DetailComponent from './Detail.vue'
import DetailInTable from './DetailInTable.vue'
import FirstView from '../FirstView.vue'

export default {
  components: {
    AppPaginator,
    CreateComponent,
    DetailComponent,
    DetailInTable,
    FirstView,
    BOverlay,
    BTable,
    BButton,
    BCollapse,
    BFormCheckbox,
  },

  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },

  data() {
    return {
      representatives: [],
      currentRepresentatives: null,
      applyOverlayRep: false,
      actions: {
        clubId: null,
        representativeId: null,
        name: '',
      },
      actionsTable: {
        clubId: null,
        representativeId: null,
        showDetail: false,
      },
      filters: {
        club_hash: null,
        instrument_number_hash: null,
        created_at: null,
        is_current: false,
      },
      paginate: {
        from: 1,
        to: 15,
        total: 0,
      },
    }
  },

  mounted() {
    if (this.$route.params.id === 'undefined' && this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
      this.$router.push({ name: 'clubs' })
      return
    }
    if (this.$route.params.id === 'undefined' && !this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
      this.$router.push({ path: `/clubs/${this.clubInfo.hash}/representative` })
    }
    this.filters.club_hash = this.$route.params.id
    this.actions.clubId = this.$route.params.id
    this.actions.name = 'list'

    this.fetchRepresentatives()
  },

  computed: {
    ...mapGetters({ colorPrimaryBtn: 'colorPrimaryBtn', clubInfo: 'clubInfo' }),

    assignRepresentativesFields() {
      return [
        {
          key: 'instrument_numbers',
          label: this.$t('clubs.instrumentNumber.labels.instrumentNumbers'),
          sortable: true,
        },
        {
          key: 'partners',
          label: this.$t('clubs.representatives.representatives'),
          sortable: true,
        },
        {
          key: 'representatives',
          label: this.$t('clubs.representatives.otherRepresentatives'),
          sortable: true,
        },
        {
          key: 'date_init',
          label: this.$t('clubs.representatives.validity'),
          sortable: true,
        },
        {
          key: 'show_details',
          label: '',
          sortable: false,
        },
      ]
    },
  },

  methods: {
    checkPermission,
    ...mapActions(['showAssemblyByClubId', 'deletePowerByClubId']),

    assignView(pTypeView) {
      this.actions.representativeId = null
      this.actions.name = pTypeView

      if (pTypeView == 'list') {
        this.fetchRepresentatives()
        this.returnTop()
      }
    },

    fetchRepresentatives() {
      this.applyOverlayRep = true

      const params = {
        included: 'instrumentNumbers,partners,representatives',
        perPage: this.paginate.to,
        page: this.paginate.from,
      }

      representativeService.getRepresentatives(params, this.filters).then(({ data }) => {
        this.paginate.total = data.total

        if (data.total > 0) {
          this.currentRepresentatives = data.data.filter(item => item.is_current)
          this.representatives = data.data.filter(item => !item.is_current)

          setTimeout(() => { this.actions.representativeId = this.currentRepresentatives[0].hash }, 300)
        } else this.applyOverlayRep = false
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    editAssignRepresentatives(pRepresentativeId) {
      this.actions.representativeId = pRepresentativeId
      this.actions.name = 'edit'
    },

    deleteAssignRepresentatives(pRepresentativeIdX) {
      const powerParamX = {
        clubId: this.$route.params.id,
        representativeId: pRepresentativeIdX,
      }

      this.deletePowerByClubId(powerParamX)
    },

    changePaginate(paginateData) {
      this.paginate.to = paginateData.perPage
      this.paginate.from = paginateData.page
      this.fetchRepresentatives()
    },

    assignDetailRep(pRepresentativeIdB, pCheckboxValue, pIndex) {
      if (pCheckboxValue) {
        this.actionsTable.showDetail = false

        for (let i = 0; i <= this.$refs.historyRefPOA.localItems.length - 1; i++) {
          if (i != pIndex) {
            if (this.$refs.historyRefPOA.localItems[i]._showDetails != undefined) {
              this.$refs.historyRefPOA.localItems[i]._showDetails = false
            }
          }
        }

        this.actionsTable.showDetail = true
        this.actionsTable.clubId = parseInt(this.$route.params.id, 10)
        this.actionsTable.representativeId = pRepresentativeIdB
      }
    },

    returnTop() {
      const rootEle = document.documentElement

      rootEle.scrollTo({
        top: 50,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style scoped>
.i-text-details-so {
  font-size: 12px;
  color: #33334d;
  visibility: visible;
  cursor: pointer;
}

.i-text-details-so:hover {
  color: #6abeff;
}

.i-min-height-register-so {
  min-height:300px;
}

.i-check-hide-co {
  visibility: hidden;
}

.i-icon-arrow-up {
  padding-bottom: 2px;
}

.i-icon-arrow-down {
  padding-top: 2px;
  transform: rotate(180deg);
}
</style>
