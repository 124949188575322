<template>
  <b-row v-if="!applyOverlay">
    <!-- Assignación de representantes -->
    <b-col md="12">
      <b-row>
        <b-col md="6">
          <h5 class="mt-1">
            <strong>{{ $t('clubs.representatives.representativesData') }}</strong>
          </h5>
        </b-col>
        <b-col md="6">
          <span
            v-if="nRepresentatives > 1 && checkPermission(['DELETE_ASSIGN_REPRESENTATIVES', 'ALLOW_ALL'])"
            class="float-right mt-1 ml-1"
          >
            {{ $t( 'buttons.delete') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="danger"
              @click="$emit('delete-representative', actions.representativeId)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </span>
          <span
            v-if="checkPermission(['EDIT_ASSIGN_REPRESENTATIVES', 'ALLOW_ALL'])"
            class="float-right mt-1"
          >
            {{ $t( 'buttons.edit') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="secondary"
              @click="$emit('edit-representative', actions.representativeId)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </span>
        </b-col>
      </b-row>
      <hr style="margin-top: 8px;">
    </b-col>
    <b-col md="12">
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.instrumentNumbers') }}</strong>
            </b-col>
            <b-col md="6">
              <span
                v-for="(instrument, index) in representativesInfo.instrument_numbers"
                :key="instrument.hash"
                class="float-left"
              >
                <span v-if="index != 0">, </span>
                {{ instrument.instrument_number }}
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.representatives.representatives') }}</strong>
            </b-col>
            <b-col md="6">
              <span
                v-for="(partnerR, index) in representativesInfo.partners"
                :key="partnerR.hash"
                class="float-left"
              >
                <span v-if="index != 0">, </span>
                <strong>{{ partnerR.name }}</strong>
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.representatives.otherRepresentatives') }}</strong>
            </b-col>
            <b-col md="6">
              <span
                v-for="(otherR, index) in representativesInfo.representatives"
                :key="otherR.hash"
                class="float-left"
              >
                <span v-if="index != 0">, </span>
                <strong>{{ otherR.name }}</strong>
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.representatives.powersGranted') }}</strong>
            </b-col>
            <b-col md="6">
              <span
                v-for="(powerG, index) in representativesInfo.power_granteds"
                :key="powerG.hash"
                class="float-left"
              >
                <span v-if="index != 0">, </span>
                {{ powerG.labelables[0].label }}
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.representatives.specialPowers') }}</strong>
            </b-col>
            <b-col md="6">
              <span
                v-for="powerS in representativesInfo.power_granteds"
                :key="powerS.hash"
                class="float-left"
              >
                {{ powerS.pivot.special_powers_granted }}
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.representatives.validityPeriod') }}</strong>
            </b-col>
            <b-col md="6">
              <span
                v-if="!representativesInfo.undefined_date"
                class="float-left"
              >
                {{ representativesInfo.date_init | formatDate }} {{ $t('clubs.representatives.to') }} {{ representativesInfo.date_end | formatDate }}
              </span>
              <span
                v-else
                class="float-left"
              >
                {{ $t('generic.undefinedDate') }}
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <!--/ Assignación de representantes -->
  </b-row>
  <b-row v-else>
    <b-col
      md="12"
      class="i-height-div"
    />
  </b-row>
</template>

<script>
import representativeService from '@/services/representativeService'
import { BListGroup, BListGroupItem, BButton } from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BButton,
  },

  props: {
    applyOverlay: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Object,
      default: () => {},
    },
    nRepresentatives: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      representativesInfo: {},
    }
  },

  watch: {
    'actions.representativeId': function () {
      this.fetchAssignRepresentativeInfo()
    },
  },

  methods: {
    fetchAssignRepresentativeInfo() {
      representativeService.getRepresentativeInfo(this.actions.representativeId).then(({ data }) => {
        this.representativesInfo = data.data
        this.$emit('stop-overlay')
      }).catch(error => {
        this.$emit('stop-overlay')
        this.responseCatch(error)
      })
    },
  },
}
</script>

<style scoped>
.i-c-pointer:hover {
  color: #6abeff;
}
</style>
